import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

// Example Usage:         <Icon name="globe" size="large" />
export function Icon({
  name,
  size = 'large',
  isLightIcon = false,
  hasIconStyle = true,
  // eslint-disable-next-line react/prop-types
  customStyles,
  alt,
}) {
  const iconSize = size === 'large' ? 'large' : 'small';

  const [iconSrc, setIconSrc] = React.useState(null);

  React.useEffect(() => {
    let isMounted = true;
    // Dynamically import .svg files from /src/resources/icons/small and /src/resources/icons/large based on folder name (size) and file name (icon name)
    import(`../../resources/icons/${iconSize}/${name}.svg`)
      .then((iconModule) => {
        if (isMounted) {
          setIconSrc(iconModule.default || iconModule);
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error) => {
        // console.error(`Failed to load icon: ${name}`, error);
      });

    // Cleanup function to prevent memory leak, unmounts component when no longer in use
    return () => {
      isMounted = false;
    };
  }, [name, iconSize]);

  // While Icon is loading (or does not exist), render nothing
  if (!iconSrc) return null;

  const iconStyleClasses = ['icon', `icon__${size}`].filter(Boolean).join(' ');

  const iconStyles = {
    stroke: isLightIcon ? '#FFFFFF' : '#4B4C51', // Adjust as needed
  };

  if (customStyles) {
    Object.assign(iconStyles, customStyles);
  }

  return (
    <SVG
      src={iconSrc}
      alt={alt || `${name} icon`}
      className={iconStyleClasses}
      style={hasIconStyle ? iconStyles : null}
    />
  );
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'large']),
  isLightIcon: PropTypes.bool,
  hasIconStyle: PropTypes.bool,
  alt: PropTypes.string,
};
