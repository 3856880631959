import React, { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';

const UserMenu = ({ authed, handleSignOut }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {/* If not signed in, show "Sign In" button */}
      {!authed ? (
        <button
          className="sign-in-button"
          onClick={() => (window.location.href = '/auth/signin')}
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            fontSize: '14px',
            fontFamily: '"Atkinson Hyperlegible"',
            color: 'var(--FRM-Primary-primary-900, #0D2B58)',
          }}
        >
          Sign In
        </button>
      ) : (
        <>
          {/* Button with SVG Icon (when signed in) */}
          <button
            className="user-menu__button"
            onClick={handleClick}
            style={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              padding: '0',
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
            >
              <g clipPath="url(#clip0_10463_37039)">
                <ellipse
                  cx="16.5"
                  cy="13.2"
                  rx="3.7125"
                  ry="3.7125"
                  stroke="#174995"
                  strokeWidth="2"
                />
                <path
                  d="M8.21288 25.1089C9.11136 23.7342 10.3377 22.6045 11.7815 21.8218C13.2252 21.039 14.8411 20.6278 16.4834 20.625C18.1257 20.6223 19.7429 21.0281 21.1892 21.806C22.6356 22.5839 23.8658 23.7094 24.7688 25.0811"
                  stroke="#174995"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <circle
                  cx="16.5"
                  cy="16.5"
                  r="12.2"
                  stroke="#174995"
                  strokeWidth="2"
                />
              </g>
              <defs>
                <clipPath id="clip0_10463_37039">
                  <rect width="33" height="33" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>

          {/* Dropdown Menu */}
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 3,
              sx: {
                borderRadius: '8px',
                background: '#FFF',
                boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
                width: '180px',
                padding: '8px 0',
              },
            }}
          >
            <MenuItem
              onClick={() => (window.location.href = '/dashboard')}
              sx={{
                color: 'var(--FRM-Primary-primary-900, #0D2B58)',
                fontFamily: '"Atkinson Hyperlegible"',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '22px',
              }}
            >
              Dashboard
            </MenuItem>
            <MenuItem
              onClick={() => (window.location.href = '/dashboard#3')}
              sx={{
                color: 'var(--FRM-Primary-primary-900, #0D2B58)',
                fontFamily: '"Atkinson Hyperlegible"',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '22px',
              }}
            >
              Manage Account
            </MenuItem>
            <MenuItem
              onClick={() => (window.location.href = '/dashboard#1')}
              sx={{
                color: 'var(--FRM-Primary-primary-900, #0D2B58)',
                fontFamily: '"Atkinson Hyperlegible"',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '22px',
              }}
            >
              Manage Resources
            </MenuItem>
            <MenuItem
              onClick={handleSignOut}
              sx={{
                color: 'red',
                fontFamily: '"Atkinson Hyperlegible"',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '22px',
              }}
            >
              Sign Out
            </MenuItem>
          </Menu>
        </>
      )}
    </div>
  );
};

UserMenu.propTypes = {
  authed: PropTypes.bool.isRequired,
  handleSignOut: PropTypes.func.isRequired,
};

export default UserMenu;
