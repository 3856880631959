import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IonIcon from '@reacticons/ionicons';
import Data from '../../resources/FooterContent.json';
import FCILogo from '../../resources/FCI-logo.svg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function Footer({ data, logo }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [setAccountModal] = useState(0);
  const user = useSelector((state) => state.auth.user);

  return (
    <div className="footer">
      {/* {accountModal === 1 && <SignIn setAccountModal={setAccountModal} />}
      {accountModal === 2 && <SignUp setAccountModal={setAccountModal} />} */}
      <a className="footer__logo" href={logo[1]}>
        <img src={logo[0]} alt="Florida Community Innovation logo" />
      </a>
      <div className="footer__content">
        {data.map((column, index) => (
          <div key={index} className="footer__column">
            <div className="footer__header">{t(column.title)}</div>
            {data[index].items.map((item) =>
              item.action ? (
                <span
                  key={item.label}
                  onClick={() => {
                    // eslint-disable-next-line no-unused-expressions
                    !user
                      ? setAccountModal(1)
                      : navigate(`/dashboard/${user.userId}`);
                  }}
                  className="footer__link"
                >
                  {t(item.label)}
                </span>
              ) : (
                <a
                  key={item.label}
                  href={item.url}
                  className="footer__link"
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.label.toLowerCase() === 'facebook' && (
                    <IonIcon
                      name="logo-facebook"
                      className="footer__link-logo"
                    />
                  )}
                  {item.label.toLowerCase() === 'linkedin' && (
                    <IonIcon
                      name="logo-linkedin"
                      className="footer__link-logo"
                    />
                  )}
                  {item.label.toLowerCase() === 'instagram' && (
                    <IonIcon
                      name="logo-instagram"
                      className="footer__link-logo"
                    />
                  )}
                  {item.label.toLowerCase() === 'twitter' && (
                    <IonIcon
                      name="logo-twitter"
                      className="footer__link-logo"
                    />
                  )}
                  {item.label.toLowerCase() === 'email' && (
                    <IonIcon name="mail" className="footer__link-logo" />
                  )}
                  {t(item.label)}
                </a>
              )
            )}
          </div>
        ))}
      </div>
      {/* <div className="footer__guide">&nbsp;</div> */}
    </div>
  );
}

Footer.propTypes = {
  /**
   * Logo is an array with the image filepath as the first entry and the url as the second.
   */
  logo: PropTypes.array,
  /**
   * Data is an array containing footer content. Content is automatically laid out and social
   * media icons are automatically generated. Each array entry contains an object, with
   * the first key being the title and the second being an object with additional
   * objects for all item values and their respective urls. Please look up
   * FooterContent.json for structure or simply edit and use that file to display
   * content. The maximum number of footer content columns is four.
   */
  data: PropTypes.array,
};

Footer.defaultProps = {
  logo: [FCILogo, 'https://floridacommunityinnovation.com/'],
  data: Data,
};
