import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Slider, Typography, Button } from '@mui/material';

export function ZoomSlider({ zoomLevel, setZoomLevel }) {
  const [showSlider, setShowSlider] = useState(false);
  const sliderRef = useRef(null); // Ref for the slider element

  const handleZoomChange = (event, newValue) => {
    setZoomLevel(newValue);
    document.body.style.zoom = `${newValue}%`;
  };

  const toggleSlider = () => setShowSlider((prev) => !prev);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sliderRef.current && !sliderRef.current.contains(event.target)) {
        setShowSlider(false);
      }
    };

    if (showSlider) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSlider]);

  return (
    <div style={{ position: 'relative' }}>
      <Button
        onClick={toggleSlider}
        variant="text"
        style={{
          textTransform: 'none',
          fontFamily: 'Atkinson Hyperlegible',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '24px',
          display: 'inline-flex',
          alignItems: 'center',
          gap: '8px',
          color: '#2067D2',
          whiteSpace: 'nowrap',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M2.5 5.16669V4.16669H11.6667V5.16669M6.66667 4.16669V15.8334M4.16667 15.8334H9.16667M10.8333 10.1667V9.16669H17.5V10.1667M14.1667 9.16669V15.8334M12.9167 15.8334H15.4167"
            stroke="#2067D2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Text Size
      </Button>
      {showSlider && (
        <Box
          ref={sliderRef} // Attach ref to the slider box
          sx={{
            position: 'fixed',
            top: '100px',
            left: '78%',
            transform: 'translateX(-50%)',
            width: '300px',
            background: '#fff',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            padding: '24px',
            zIndex: 1000,
            border: '1px solid #d3d3d3',
          }}
        >
          <Typography
            variant="title"
            sx={{
              fontWeight: 'bold',
              color: 'black',
              fontFamily: 'Atkinson Hyperlegible',
            }}
          >
            Text Size Adjustment
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '10px',
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: '12px', fontWeight: 'bold', color: 'black' }}
            >
              A
            </Typography>
            <Slider
              value={zoomLevel}
              onChange={handleZoomChange}
              aria-labelledby="text-size-slider"
              valueLabelDisplay="off"
              step={10}
              min={90}
              max={130}
              sx={{ width: '80%', marginX: '8px', color: '#1976d2' }}
            />
            <Typography
              variant="body2"
              sx={{ fontSize: '18px', fontWeight: 'bold', color: 'black' }}
            >
              A
            </Typography>
          </Box>
        </Box>
      )}
    </div>
  );
}

ZoomSlider.propTypes = {
  zoomLevel: PropTypes.number.isRequired,
  setZoomLevel: PropTypes.func.isRequired,
};
