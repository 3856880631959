import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ServiceCard.css';
import { useTranslation } from 'react-i18next';

export const ServiceCard = ({
  name,
  services,
  index,
  deleteService,
  handleName,
  addDetail,
  removeDetail,
}) => {
  const [detail, setDetail] = useState('');
  const { t } = useTranslation();
  const handleAddDetail = () => {
    if (detail !== '') {
      addDetail(index, detail);
      setDetail('');
    }
  };
  return (
    <div className="ServiceCard">
      <svg
        className="ServiceCard__delete"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 15 16"
        fill="none"
        onClick={() => deleteService(index)}
      >
        <path
          d="M4.5 5L7.5 8M10.5 11L7.5 8M7.5 8L4.5 11M7.5 8L10.5 5"
          stroke="#2C2D30"
          strokeLinecap="round"
        />
      </svg>
      <div className="ServiceCard__textField">
        Service Name
        <input
          type="text"
          placeholder="Service name"
          defaultValue={name}
          className="ServiceCard__name"
          onChange={handleName(index)}
        />
        <path
          d="M4.5 5L7.5 8M10.5 11L7.5 8M7.5 8L4.5 11M7.5 8L10.5 5"
          stroke="#2C2D30"
          strokeLinecap="round"
        />
      </div>
      <div className="ServiceCard__textField">
        {t('Service Name')}
        <input
          type="text"
          placeholder={t('Service Name')}
          defaultValue={name}
          className="ServiceCard__name"
          onChange={handleName(index)}
        />
      </div>

      <div className="ServiceCard__textField">
        {t('Services')}
        <div className="ServiceCard__servicesArea">
          <input
            type="text"
            placeholder={t('Service detail')}
            className="ServiceCard__service"
            value={detail}
            onChange={(e) => setDetail(e.target.value)}
          />
          <button
            className="ServiceCard__add"
            onClick={() => handleAddDetail()}
          >
            {' '}
            {t('Add')}{' '}
          </button>{' '}
        </div>
      </div>

      <ul className="ServiceCard__textField">
        {services.map((service, detailIndex) => (
          <li key={detailIndex}>
            <div className="ServiceCard__listArea">
              {service}
              <svg
                className="ServiceCard__deleteDetail"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 15 16"
                fill="none"
                onClick={() => removeDetail(index, detailIndex)}
              >
                <path
                  d="M4.5 5L7.5 8M10.5 11L7.5 8M7.5 8L4.5 11M7.5 8L10.5 5"
                  stroke="#2C2D30"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

ServiceCard.defaultProps = {
  name: '',
  services: [],
};

ServiceCard.propTypes = {
  name: PropTypes.string,
  services: PropTypes.array,
  index: PropTypes.number.isRequired,
  deleteService: PropTypes.func.isRequired,
  handleName: PropTypes.func.isRequired,
  addDetail: PropTypes.func.isRequired,
  removeDetail: PropTypes.func.isRequired,
};
