import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './_RemovableTags.scss';

// TOCHECK: Icon?
export function RemovableTags({
  useBackground,
  variant,
  text,
  onRemove, // Add onRemove prop for the X icon
}) {
  const { t } = useTranslation();
  return (
    <div
      className={`removableTags ${useBackground || 'removableTags--no-background'} removableTags--${variant}`}
    >
      <div className="removableTags--icon-wrapper">
        {/* {icon ? <TagIcon tag={icon.toLowerCase()} />: ''} */}
      </div>
      {t(text)}
      {onRemove && (
        <button
          className="removableTags--remove-button"
          onClick={(e) => {
            e.stopPropagation(); // Prevent click event from bubbling up
            onRemove();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M6 6L10 10M14 14L10 10M10 10L6 14M10 10L14 6"
              stroke="#4B4C51"
              strokeWidth="1.25"
              strokeLinecap="round"
            />
          </svg>
        </button>
      )}
    </div>
  );
}

RemovableTags.propTypes = {
  /**
   * Which variation of the removableTag do you wish to be displayed?
   */
  variant: PropTypes.oneOf(['success', 'error', 'warning', 'general'])
    .isRequired,
  /**
   * What message do you want to display?
   */
  text: PropTypes.string.isRequired,
  /**
   * Do you want to display the background color?
   */
  useBackground: PropTypes.bool,
  onRemove: PropTypes.func, // Add onRemove prop for the X icon
  icon: PropTypes.string,
};

RemovableTags.defaultProps = {
  useBackground: true,
  icon: 'time-outline',
};
