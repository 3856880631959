/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import filters from '../../components/FilterPanel/filters.json';
// import tags from '../../components/FilterPanel/tags.json';
import { useDispatch, useSelector } from 'react-redux';
import { filterInputsActions } from '../../store/filterInput-slice';
import { useMediaQuery } from 'react-responsive';
import { currentPageActions } from '../../store/currentPage-slice';
import { RemovableTags } from '../../components/RemovableTags/RemovableTags';
import { Select, MenuItem, Dialog } from '@mui/material';
import {
  Footer,
  Header,
  Card,
  FilterPanel,
  ResourceMap,
  SearchResponsive,
  Pagination1,
  FilterButton,
  LoginPrompt,
} from '../../components';
import Typography from '@mui/material/Typography';

import getDistance from 'geolib/es/getDistance';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { fetchAddress } from '../../store/enteredLocation-slice';
import axios from '../../apis/backend';
import i18n from '../../_locales/i18n';
import { useTranslation } from 'react-i18next';

export function Search() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const [searchParams, setSearchParams] = useSearchParams();

  const [totalResults, setTotalResults] = useState(0);

  const [isFiltering, setIsFiltering] = useState(false);

  //Check if logged in
  const user = useSelector((state) => state.auth.user);
  const authed = user?.roles?.includes('100001');
  const [login, setLogin] = useState(false);
  const navigate = useNavigate();

  const toggleLogin = () => {
    setLogin(!login);
  };
  const handleNavigate = (path) => {
    navigate(path);
  };

  const filterInputs = useSelector((state) => state.filterInputs.filters);
  const currentPage = useSelector((state) => state.currentPage.page);
  const enteredTerm = useSelector((state) => state.enteredTerm.input);
  const enteredLocation = useSelector((state) => state.enteredLocation.input);
  const locationCoords = useSelector((state) => state.coords.coordinates);

  const [editingFilters, setEditingFilters] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allPins, setAllPins] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [active, setActive] = useState(-1);
  const [isSearching, setIsSearching] = useState(false);
  const [andTags, setAndTags] = useState(false);

  const filteredPins = allPins; //allPins.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  useEffect(() => {
    if (!enteredLocation && searchParams.has('address')) {
      const urlCoords = JSON.parse(searchParams.get('address'));
      dispatch(fetchAddress(urlCoords));
    }
  }, [dispatch, enteredLocation, searchParams]);

  const handlePageSizeChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    setPageSize(newSize);
    dispatch(currentPageActions.pageHandler(1)); // Reset to page 1
    setURL(1);
    setIsSearching(true);
  };

  function formatServices(services) {
    let validTitles = services
      .filter((service) => service?.title?.length <= 35)
      .slice(0, 5)
      .map((service) => service.title);
    return validTitles;
  }

  let total = [];
  Object.keys(filterInputs).forEach((key) => {
    if (filterInputs[key]) {
      total.push(key);
    }
  });

  // console.log('Active filters (total array):', total);
  const toggleDrawer = () => {
    setEditingFilters(!editingFilters);
  };

  function activeScroll(index) {
    setActive(index);
  }

  useEffect(() => {
    const elem = document.getElementsByClassName(`resourcecard_${active}`)[0];
    if (elem) {
      elem.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [active]);

  // Memoized construction of the initialFilters array
  const initialFilters = useMemo(() => {
    const filtersArray = [];

    // Extract filter options into an array
    Object.keys(filters).forEach((key) => {
      filters[key].options.forEach((option) => {
        filtersArray.push(option.name);
      });
    });
    return filtersArray;
  }, []);

  useEffect(() => {
    // Initialize filters from URL
    const setFilters = async () => {
      const initialFilterState = initialFilters.reduce((state, prop) => {
        state[prop] = false;
        return state;
      }, {});

      initialFilterState.Distance = searchParams.get('distance') || 'any';

      if (searchParams.has('tag')) {
        const arr = searchParams.get('tag').split(',');

        arr.forEach((item) => {
          const itemTrimmed = item.trim();
          initialFilterState[itemTrimmed] = true;
        });
      }

      await dispatch(filterInputsActions.handlefilters(initialFilterState));
      setIsSearching(true);
    };

    setFilters();
  }, [searchParams, dispatch, initialFilters]);

  // set URL for frontend
  const setURL = useCallback(
    (page) => {
      const filtersString = Object.keys(filterInputs)
        .filter(
          (key) => typeof filterInputs[key] === 'boolean' && filterInputs[key]
        )
        .join(',');

      const distanceString = filterInputs.Distance;
      const verifierString = filterInputs?.Verifier?.join(',');
      const params = {
        page: page,
        distance: distanceString || undefined,
        tag: filtersString || undefined,
        search: enteredTerm || undefined,
        address: locationCoords ? JSON.stringify(locationCoords) : undefined,
        verifier: verifierString || undefined,
      };

      Object.keys(params).forEach((key) => {
        if (params[key] === undefined || params[key] === '') {
          delete params[key];
        }
      });

      setSearchParams(params, { replace: true });
    },
    [filterInputs, enteredTerm, locationCoords, setSearchParams]
  );

  // Fetch resources function with memoization
  const fetchResources = useCallback(
    async (page) => {
      setIsLoading(true);
      setAllPins([]);

      let termParams = searchParams.has('search')
        ? enteredTerm.toLowerCase()
        : '';
      let addressParams = searchParams.has('address')
        ? JSON.parse(searchParams.get('address'))
        : {};
      let distanceParams = searchParams.get('distance') || '';
      let verifierParams = searchParams.get('verifier') || '';
      let languageParam = i18n.language;

      let activeFilters = Object.keys(filterInputs)
        .filter(
          (key) =>
            filterInputs[key] === true && key !== 'Open Now' && key !== 'Online'
        ) // Only include selected filters
        .map((key) => key.toLowerCase())
        .join(',');

      // flags separate from traditional tags (activeFilters)
      const includeOpen = filterInputs['Open Now'] === true ? 'true' : 'false'; // flag to filter resources that are open
      const includeOnline = filterInputs['Online'] === true ? 'true' : 'false'; // flag to filter resources that are online

      const params = {
        search: termParams,
        page: page - 1, // Backend expects 0-indexed pages
        limit: pageSize,
        longitude: addressParams.lng || '',
        latitude: addressParams.lat || '',
        distance: distanceParams,
        tags: activeFilters || '',
        verifiedBy: verifierParams, // Used to be called verifier
        openNow: includeOpen,
        online: includeOnline,
        language: languageParam,
      };

      try {
        const response = await axios.get('/resource/search', { params });
        const {
          results = [],
          totalCount = 0,
          totalPages = 0,
        } = response?.data?.payload || {};

        results.forEach((resource) => {
          resource.longitude = resource?.coords?.coordinates?.[0] || null;
          resource.latitude = resource?.coords?.coordinates?.[1] || null;
        });

        setAllPins(results);
        setTotalResults(totalCount || results.length);
        setTotalPages(totalPages);
      } catch (error) {
        console.error('Error fetching the data:', error);
      } finally {
        setIsLoading(false);
        window.scrollTo(0, 0);
      }
    },
    [filterInputs, pageSize, searchParams, enteredTerm] // Dependencies
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsSearching(true);
      setIsFiltering(true);
      const page = isFiltering ? 1 : currentPage; // Keep current page if searching, reset to 1 otherwise
      await setURL(isFiltering ? 1 : currentPage);
      await fetchResources(page);
      setIsFiltering(false);
      setIsSearching(false);
    };
    if (isSearching) {
      fetchData();
    }
  }, [isSearching, fetchResources, setURL]);

  useEffect(() => {
    if (currentPage !== searchParams.get('page')) {
      dispatch(currentPageActions.pageHandler(searchParams.get('page')));
    }
  }, [searchParams, dispatch, currentPage]);

  // Define a function to extract the ZIP code from the address
  const getZipCode = (address) => {
    const zipCodeMatch = address.match(/\b\d{5}\b/); // Matches a 5-digit ZIP code
    return zipCodeMatch ? zipCodeMatch[0] : address; // Return ZIP if found, otherwise full address
  };

  // Get tag and zipcode from search parameters
  const zipcode = enteredLocation ? getZipCode(enteredLocation) : '';

  // Construct message based on the conditions
  let resultsMessage = `${totalResults} Results`;
  if (enteredTerm && zipcode) {
    resultsMessage += ` for "${enteredTerm}" near ${zipcode}`;
  } else if (enteredTerm) {
    resultsMessage += ` for "${enteredTerm}"`;
  } else if (zipcode) {
    resultsMessage += ` near ${zipcode}`;
  }

  // Functions and state information for resource cards
  const cards = filteredPins.map((result, index) => (
    <Card
      // Login verification props
      loggedIn={authed}
      triggerLogin={toggleLogin}
      id={result._id}
      key={index}
      index={pageSize * (currentPage - 1) + (index + 1)}
      header={result.name}
      address={result.address}
      distance={
        locationCoords && result.latitude && result.longitude
          ? `${(
              getDistance(
                {
                  latitude: locationCoords.lat, // Updated by Sam--may not be correct
                  longitude: locationCoords.lng,
                },
                { latitude: result.latitude, longitude: result.longitude }
              ) * 0.000621371
            ).toFixed(1)}  miles from your location`
          : ''
      }
      website={result.website}
      hours={result.hours}
      phone_string={result.phone_string}
      phone={result.phone}
      tags={Array.isArray(result.tags) ? result.tags : []}
      services={formatServices(result.services)}
      verifiedBy={result.verifiedBy}
      active={result.id === active}
      classScroll={`resourcecard_${result.id}`}
      timeLastUpdate={result.timeLastUpdate}
      description={result.description}
    />
  ));

  const pickPageSx = {
    height: '40px',
    width: '120px',
    borderColor: '#666E87',
    'fieldset.MuiOutlinedInput-notchedOutline': {
      border: '2px solid #666E87',
      borderRadius: '10px',
    },
  };

  const menuItemStyle = {
    color: '#00233D',
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
  };

  const pageOptions = [5, 10, 20, 30];

  // End functions and state information for resource cards

  return (
    <>
      <Header landing={false} setIsSearching={setIsSearching} searchBar />
      {editingFilters && (
        <FilterPanel
          setIsLoading={setIsLoading}
          setEditingFilters={setEditingFilters}
          editingFilters={editingFilters}
          toggleDrawer={toggleDrawer}
          setIsSearching={setIsSearching}
          setAndTags={setAndTags}
          setIsFiltering={setIsFiltering}
          andTags={andTags}
        />
      )}
      {isMobile ? (
        isLoading && filteredPins.length === 0 ? (
          <div className="search-page__loading">
            {t('Please Wait Loading...')}
          </div>
        ) : (
          <SearchResponsive
            pins={allPins}
            cards={cards}
            setEditingFilters={setEditingFilters}
            totalPages={totalPages}
            setActive={setActive}
            locationCoords={locationCoords}
            refetch={fetchResources} // added by Nick
          />
        )
      ) : isLoading && filteredPins.length === 0 ? (
        <div className="search-page__loading">
          {t('Please Wait Loading...')}
        </div>
      ) : (
        <div className="search-page__main">
          <div className="search-page__results">
            <div
              className="search-page__filters"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              {!editingFilters && (
                <FilterButton
                  defaultValue={t('All Filters')}
                  filters={total.length - 1} // -1 to account for the distance tag
                  selectedValue="selected"
                  setEditingFilters={setEditingFilters}
                />
              )}
              {/* Test Tag since filtering is not working 
                <RemovableTags
                variant="general"
                text="Test Tag"
                onRemove={() => console.log('Remove Test Tag')}
                useBackground={true}
              />    */}
              <div
                className="filter-tags-container"
                style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}
              >
                {!editingFilters &&
                  initialFilters
                    .filter((item) => total.includes(item)) // Render only active filters
                    .map((item, idx) => (
                      <RemovableTags
                        key={idx}
                        variant={'general'}
                        text={t(item)}
                        onRemove={() => {
                          const updatedFilterInputs = {
                            ...filterInputs,
                            [item]: false, // Remove the clicked filter
                          };

                          dispatch(
                            filterInputsActions.handlefilters(
                              updatedFilterInputs
                            )
                          );
                          dispatch(currentPageActions.pageHandler(1));
                          setIsSearching(true);
                        }}
                        useBackground={filterInputs[item]}
                      />
                    ))}
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: '20px' }}>
              <Typography
                variant="h5"
                style={{
                  fontWeight: '600',
                  paddingLeft: '3rem',
                  marginLeft: '6rem',
                  fontSize: '2rem',
                  textAlign: 'center',
                }}
              >
                {resultsMessage}
              </Typography>
            </div>
            {cards.length > 0 ? (
              cards
            ) : (
              <div className="search-page__loading">
                <p className="p1search">
                  {t(
                    "Oops... we didn't find anything that matches this search."
                  )}
                </p>
                <p>
                  {t(
                    'Try to search for something more general, change the filters or check for spelling mistakes.'
                  )}
                </p>
              </div>
            )}
            {/* <div style={{display: "flex", marginRight: "2rem"}} > */}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <Pagination1
                totalPages={totalPages}
                refetch={fetchResources}
                setActive={setActive}
              />
              <div
                style={{
                  marginLeft: '10px',
                  marginRight: '5px',
                  marginTop: '-20px',
                }}
              >
                <Typography
                  sx={{ fontSize: 14, fontFamily: 'Nunito' }}
                  id="page-size-select-label"
                >
                  Resources per page
                </Typography>
                <Select
                  sx={pickPageSx}
                  labelId="page-size-select-label"
                  id="page-size-select"
                  value={pageSize}
                  onChange={handlePageSizeChange}
                >
                  {pageOptions.map((numPages) => (
                    // eslint-disable-next-line react/jsx-key
                    <MenuItem value={numPages}>
                      <div style={menuItemStyle}>{numPages}</div>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="search-page__map">
            <ResourceMap
              pins={allPins}
              activeScroll={activeScroll}
              resourceCounterOffset={(currentPage - 1) * pageSize}
            />
          </div>
        </div>
      )}
      <Footer />
      <Dialog
        open={login}
        onClose={toggleLogin}
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '8px',
            overflow: 'hidden',
          },
        }}
      >
        <LoginPrompt
          handleNav={handleNavigate}
          close={toggleLogin}
          save={true}
        />
      </Dialog>
    </>
  );
}
