import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  TextField,
  Menu,
  MenuItem,
  Autocomplete,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { axiosPrivate } from '../../apis/backend';

export const PartnerDropdown = ({
  partners,
  selectedPartner,
  setSelectedPartner,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleSelectPartner = (partner) => {
    setSelectedPartner(partner);
    handleDropdownClose();
  };

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleDropdownClick}
        endIcon={<ArrowDropDownIcon />}
        sx={{ textTransform: 'none' }}
      >
        {selectedPartner ? selectedPartner.name : 'Select Partner'}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
      >
        {partners.length > 0 ? (
          partners.map((partner) => (
            <MenuItem
              key={partner._id}
              onClick={() => handleSelectPartner(partner)}
            >
              {partner.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No Partners Available</MenuItem>
        )}
      </Menu>
    </Box>
  );
};

PartnerDropdown.propTypes = {
  partners: PropTypes.array.isRequired,
  selectedPartner: PropTypes.object,
  setSelectedPartner: PropTypes.func.isRequired,
};

export const ImportListDropdown = ({
  selectedPartner,
  importLists,
  selectedImportList,
  setSelectedImportList,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleSelectImportList = (importList) => {
    setSelectedImportList(importList);
    handleDropdownClose();
  };

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleDropdownClick}
        endIcon={<ArrowDropDownIcon />}
        sx={{ textTransform: 'none' }}
      >
        {selectedImportList ? selectedImportList.name : 'Select Import List'}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
      >
        {importLists.length > 0 ? (
          importLists.map((importList) => (
            <MenuItem
              key={importList._id}
              onClick={() => handleSelectImportList(importList)}
            >
              {importList.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>
            {selectedPartner
              ? 'No Import Lists Available'
              : 'Select a partner to view import lists'}
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

ImportListDropdown.propTypes = {
  selectedPartner: PropTypes.object,
  importLists: PropTypes.array.isRequired,
  selectedImportList: PropTypes.object,
  setSelectedImportList: PropTypes.func.isRequired,
};

// Add and Remove Partner Buttons
const AddPartner = ({ partners, setPartners }) => {
  const [newPartnerName, setNewPartnerName] = useState('');

  const addNewPartner = async () => {
    if (!newPartnerName.trim())
      return window.alert('Please enter a name for the new partner');
    try {
      const response = await axiosPrivate.post(
        `/partner/single?name=${encodeURIComponent(newPartnerName.trim())}`
      );
      setPartners([...partners, response.data.payload.partner]);
      setNewPartnerName('');
      window.alert(
        `New partner "${response.data.payload.partner.name}" added successfully.`
      );
    } catch (error) {
      window.alert(
        `Error adding partner: ${error.response?.data?.errMsg || error.message}`
      );
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
      <TextField
        label="New Partner Name"
        variant="outlined"
        value={newPartnerName}
        onChange={(e) => setNewPartnerName(e.target.value)}
        sx={{ minWidth: 200 }}
      />
      <Button variant="contained" onClick={addNewPartner}>
        Add Partner
      </Button>
    </Box>
  );
};

AddPartner.propTypes = {
  partners: PropTypes.array.isRequired,
  setPartners: PropTypes.func.isRequired,
};

const RemovePartnerButton = ({
  selectedPartner,
  setPartners,
  setSelectedPartner,
  setSelectedImportList,
  setImportLists,
}) => {
  const removePartner = async () => {
    if (!selectedPartner)
      return window.alert('Please select a partner to remove.');
    try {
      const response = await axiosPrivate.delete(
        `/partner/single?id=${selectedPartner._id}`
      );
      if (response.data.errCode === '0') {
        setPartners((prev) =>
          prev.filter((p) => p._id !== selectedPartner._id)
        );
        window.alert(`Partner "${selectedPartner.name}" removed successfully.`);
        setSelectedPartner(null);
        setSelectedImportList(null);
        setImportLists([]);
      } else {
        window.alert(`Error removing partner: ${response.data.errMsg}`);
      }
    } catch (error) {
      window.alert(
        `Error removing partner: ${error.response?.data?.errMsg || error.message}`
      );
    }
  };

  return (
    <Button
      variant="contained"
      color="error"
      onClick={removePartner}
      disabled={!selectedPartner}
    >
      Remove Partner
    </Button>
  );
};

RemovePartnerButton.propTypes = {
  selectedPartner: PropTypes.object,
  setPartners: PropTypes.func.isRequired,
  setSelectedPartner: PropTypes.func.isRequired,
  setImportLists: PropTypes.func.isRequired,
  setSelectedImportList: PropTypes.func.isRequired,
};

// Add and Remove Import List Buttons
export const AddImportListButton = ({
  selectedPartner,
  importLists,
  setImportLists,
}) => {
  const [newImportListName, setNewImportListName] = useState('');

  const createImportListAndAdd = async () => {
    if (!selectedPartner) return window.alert('Please select a partner first.');
    if (!newImportListName.trim())
      return window.alert('Please enter a name for the import list.');

    try {
      const response = await axiosPrivate.post(
        `/partner/importList?id=${selectedPartner._id}&name=${encodeURIComponent(newImportListName.trim())}`
      );
      setImportLists([...importLists, response.data.payload.importList]);
      setNewImportListName('');
      window.alert(
        `Import list "${newImportListName.trim()}" added to ${selectedPartner.name}.`
      );
    } catch (error) {
      window.alert(
        `Error creating import list: ${error.response?.data?.errMsg || error.message}`
      );
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
      <TextField
        label="New Import List Name"
        variant="outlined"
        value={newImportListName}
        onChange={(e) => setNewImportListName(e.target.value)}
      />
      <Button
        variant="contained"
        onClick={createImportListAndAdd}
        disabled={!selectedPartner}
      >
        Add Import List
      </Button>
    </Box>
  );
};

AddImportListButton.propTypes = {
  selectedPartner: PropTypes.object,
  importLists: PropTypes.array.isRequired,
  setImportLists: PropTypes.func.isRequired,
};

const RemoveImportListButton = ({
  selectedPartner,
  selectedImportList,
  setImportLists,
  setSelectedImportList,
}) => {
  useEffect(() => {
    setSelectedImportList(null);
  }, [setSelectedImportList, selectedPartner]);

  const removeImportList = async () => {
    if (!selectedPartner || !selectedImportList)
      return window.alert(
        'Please select a partner and an import list to remove.'
      );
    try {
      await axiosPrivate.delete(
        `/partner/importList?id=${selectedPartner._id}&importListId=${selectedImportList._id}`
      );
      setImportLists((prev) =>
        prev.filter((list) => list._id !== selectedImportList._id)
      );
      setSelectedImportList(null);
      window.alert(
        `Import list removed from partner "${selectedPartner.name}".`
      );
    } catch (error) {
      window.alert(
        `Error removing import list: ${error.response?.data?.errMsg || error.message}`
      );
    }
  };

  return (
    <Button
      variant="contained"
      color="error"
      onClick={removeImportList}
      disabled={!selectedPartner || !selectedImportList}
    >
      Remove Import List
    </Button>
  );
};

RemoveImportListButton.propTypes = {
  selectedPartner: PropTypes.object,
  selectedImportList: PropTypes.object,
  setImportLists: PropTypes.func.isRequired,
  setSelectedImportList: PropTypes.func.isRequired,
};

// Add User to Partner
const AddUserToPartnerButton = ({ selectedPartner }) => {
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedUserRole, setSelectedUserRole] = useState('');
  const [autocompleteValue, setAutocompleteValue] = useState(null);

  // Fetch available users from the database, and reset selected user and role
  useEffect(() => {
    const fetchUsers = async () => {
      if (selectedPartner) {
        try {
          // Fetch all users
          const response = await axiosPrivate.get(`/admin/users`);
          // Fetch users already attached to the partner
          const response2 = await axiosPrivate.get(
            `/partner/single?id=${selectedPartner._id}`
          );

          // Filter out users already attached to the partner, so they can't be selected
          const availableUsers = response.data.payload.users.filter(
            (user) =>
              !response2.data.payload.users.find((u) => u.opid === user.opid)
          );
          availableUsers.sort((a, b) => a.firstName.localeCompare(b.firstName));
          setUsers(availableUsers || []);
        } catch (error) {
          window.alert('Error fetching users:', error);
        }
      }
    };
    setSelectedUserId('');
    setSelectedUserRole('');
    setAutocompleteValue(null);
    fetchUsers();
  }, [selectedPartner]);

  const addUserToPartner = async () => {
    if (!selectedPartner || !selectedUserId || !selectedUserRole) {
      return window.alert('Please select a partner, a user, and a role.');
    }

    try {
      await axiosPrivate.post(
        `/partner/addUser?partnerId=${selectedPartner._id}&userId=${selectedUserId}&role=${selectedUserRole}`
      );
      window.alert(`User added to partner "${selectedPartner.name}".`);
      setSelectedUserId('');
      setSelectedUserRole('');
      setAutocompleteValue(null);
      setUsers((prev) => prev.filter((user) => user.opid !== selectedUserId));
    } catch (error) {
      window.alert(
        `Error adding user: ${error.response?.data?.errMsg || error.message}`
      );
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
      <Autocomplete
        value={autocompleteValue}
        getOptionLabel={(user) =>
          `${(user.firstName || '') + ' ' + (user.lastName || '')}`.trim() +
            (user.email ? ` (${user.email})` : '') || 'Unnamed User'
        }
        options={users}
        onChange={(_, user) => {
          setAutocompleteValue(user);
          setSelectedUserId(user?.opid || '');
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.opid}>
            {`${(option.firstName || '') + ' ' + (option.lastName || '')}`.trim() +
              (option.email ? ` (${option.email})` : '') || 'Unnamed User'}
          </li>
        )}
        isOptionEqualToValue={(option, value) => option.opid === value.opid}
        renderInput={(params) => (
          <TextField {...params} label="Select User" sx={{ minWidth: 200 }} />
        )}
        noOptionsText={
          selectedPartner ? 'No options' : 'Select a partner to add a user'
        }
        sx={{ minWidth: 300 }}
      />
      <TextField
        select
        label="Select Role"
        value={selectedUserRole}
        onChange={(e) => setSelectedUserRole(e.target.value)}
        sx={{ minWidth: 200 }}
      >
        <MenuItem key="admin" value="admin">
          Admin
        </MenuItem>
        <MenuItem key="editor" value="editor">
          Editor
        </MenuItem>
      </TextField>
      <Button
        variant="contained"
        onClick={addUserToPartner}
        disabled={!selectedPartner || !selectedUserId || !selectedUserRole}
      >
        Add User to Partner
      </Button>
    </Box>
  );
};

AddUserToPartnerButton.propTypes = {
  selectedPartner: PropTypes.object,
};

// Remove User from Partner
const RemoveUserFromPartnerButton = ({ selectedPartner }) => {
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  // Fetch all users from the database
  useEffect(() => {
    const fetchUsers = async () => {
      if (selectedPartner) {
        try {
          const response = await axiosPrivate.get(
            `/partner/single?id=${selectedPartner._id}`
          );
          setUsers(response.data.payload.users || []);
        } catch (error) {
          window.alert(
            `Error fetching users: ${error.response?.data?.errMsg || error.message}`
          );
        }
      }
    };
    fetchUsers();
  }, [selectedPartner]);

  const removeUserFromPartner = async () => {
    if (!selectedPartner || !selectedUserId) {
      return window.alert('Please select a partner and a user.');
    }

    try {
      await axiosPrivate.delete(
        `/partner/removeUser?partnerId=${selectedPartner._id}&userId=${selectedUserId}`
      );
      window.alert(`User removed from partner "${selectedPartner.name}".`);
      setSelectedUserId('');
      setUsers((prev) => prev.filter((user) => user.opid !== selectedUserId));
    } catch (error) {
      window.alert(
        `Error removing user: ${error.response?.data?.errMsg || error.message}`
      );
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
      <TextField
        select
        label="Select User"
        value={selectedUserId}
        onChange={(e) => setSelectedUserId(e.target.value)}
        sx={{ minWidth: 200 }}
      >
        {users.map((user) => (
          <MenuItem key={user.opid} value={user.opid}>
            {`${user.firstName || ''} ${user.lastName || ''}`.trim() ||
              'Unnamed User'}
            {user.email ? ` (${user.email})` : ''}{' '}
            {/* Display full name with fallback */}
          </MenuItem>
        ))}
      </TextField>
      <Button
        variant="contained"
        color="error"
        onClick={removeUserFromPartner}
        disabled={!selectedPartner || !selectedUserId}
      >
        Remove User
      </Button>
    </Box>
  );
};

RemoveUserFromPartnerButton.propTypes = {
  selectedPartner: PropTypes.object,
  selectedImportList: PropTypes.object,
};

export function DevButtons() {
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [importLists, setImportLists] = useState([]);
  const [selectedImportList, setSelectedImportList] = useState(null);

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await axiosPrivate.get('/partner/all');
        if (response.data.errCode === '0') {
          setPartners(response.data.payload.partners || []);
        } else {
          window.alert(`Error fetching partners: ${response.data.errMsg}`);
        }
      } catch (error) {
        window.alert(
          `Error fetching partners: ${error.response?.data?.errMsg || error.message}`
        );
      }
    };
    const fetchImportLists = async () => {
      try {
        const response = await axiosPrivate.get(
          `/partner/single?id=${selectedPartner._id}`
        );
        setImportLists(response.data.payload.partner.resourceImportLists || []);
      } catch (error) {
        window.alert('Error fetching import lists:', error);
      }
    };
    fetchPartners();
    if (selectedPartner) fetchImportLists();
  }, [selectedPartner]);

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <PartnerDropdown
          partners={partners}
          selectedPartner={selectedPartner}
          setSelectedPartner={setSelectedPartner}
        />
        <RemovePartnerButton
          selectedPartner={selectedPartner}
          setPartners={setPartners}
          setSelectedPartner={setSelectedPartner}
          setImportLists={setImportLists}
          setSelectedImportList={setSelectedImportList}
        />
      </Box>
      <AddPartner partners={partners} setPartners={setPartners} />
      <AddImportListButton
        selectedPartner={selectedPartner}
        importLists={importLists}
        setImportLists={setImportLists}
      />
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <ImportListDropdown
          selectedPartner={selectedPartner}
          importLists={importLists}
          selectedImportList={selectedImportList}
          setSelectedImportList={setSelectedImportList}
        />
        <RemoveImportListButton
          selectedPartner={selectedPartner}
          selectedImportList={selectedImportList}
          setImportLists={setImportLists}
          setSelectedImportList={setSelectedImportList}
        />
      </Box>
      <AddUserToPartnerButton selectedPartner={selectedPartner} />
      <RemoveUserFromPartnerButton selectedPartner={selectedPartner} />
    </Box>
  );
}
