import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Card } from '../../components/Card/Card'; // Reuse the Card component
import { axiosPrivate } from '../../apis/backend';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/Button/Button';
import { handleBrowseAll } from '../../components/BrowseAll/BrowseAll';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

export const MySavedResources = ({ setIsSearching }) => {
  const [savedResources, setSavedResources] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    handleBrowseAll(dispatch, navigate, setIsSearching);
  };

  function formatServices(services) {
    let validTitles = services
      .filter((service) => service?.title?.length <= 35)
      .slice(0, 5)
      .map((service) => service.title);
    return validTitles;
  }

  // Fetch saved resources on component mount
  useEffect(() => {
    const fetchSavedResources = async () => {
      try {
        const response = await axiosPrivate.get('/user/savedResources');
        // console.log(response.data.payload.resources);
        if (response.status === 200 && response.data.errCode === '0') {
          // Access the resources inside the payload
          setSavedResources(response.data.payload.resources);
        } else {
          // console.error('Unexpected response format:', response);
        }
      } catch (error) {
        console.error('Error fetching saved resources:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSavedResources();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  // Mapping over savedResources just like Search.js maps over filteredPins
  const cards = savedResources.map((resource, index) => (
    <Card
      id={resource.resourceId}
      key={index}
      index={index + 1}
      header={resource.name || 'Unknown Resource'}
      address={resource.address || ''}
      distance={resource.distance || ''} // Customize how you display the distance
      website={resource.website || ''}
      phone_string={resource.phone_string || ''}
      phone={resource.phone || {}}
      tags={Array.isArray(resource.tags) ? resource.tags : []} // Ensure tags are passed here
      services={formatServices(resource.services)}
      verifiedBy={resource.verifiedBy || 'Unverified'}
      active={false} // Control active status here if needed
      classScroll={`resourcecard_${resource.resourceId}`} // Optional class scroll
      timeLastUpdate={resource.timeLastUpdate || 'Not Updated'}
      description={
        typeof resource.description === 'string'
          ? resource.description
          : 'No description available'
      }
      // eslint-disable-next-line no-console
      triggerLogin={() => console.log('triggerLogin function not defined')} // Temporary fallback
      loggedIn={true} // Pass the correct authentication state here
    />
  ));

  return (
    <Box>
      {savedResources.length > 0 ? (
        <div>
          {cards}{' '}
          {/* This renders the cards created by mapping saved resources */}
        </div>
      ) : (
        <>
          <Typography
            sx={{
              fontFamily: 'Nunito',
              fontSize: 18,
              fontStyle: 'normal',
              fontWeight: 700,
              // lineHeight: 4
              textTransform: 'none',
              color: '#000000',
              textAlign: 'center',
              paddingLeft: '1rem',
              PaddingRight: '1rem',
              paddingTop: '4rem',
              paddingBottom: '3rem',
            }}
          >
            {"You haven't saved any resources yet."}
          </Typography>

          <Typography
            sx={{
              fontFamily: 'Nunito',
              fontSize: 18,
              fontStyle: 'normal',
              fontWeight: 700,
              // lineHeight: 4
              textTransform: 'none',
              color: '#000000',
              textAlign: 'center',
              paddingLeft: '1rem',
              PaddingRight: '1rem',
              paddingTop: '2rem',
            }}
          >
            {'Click below to find resources to save.'}
          </Typography>

          <div
            style={{
              padding: '2rem',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              label={t('Browse for Resources')}
              type="button"
              icon="arrow-forward"
              variant="browse-all-button"
              onClick={handleClick}
            />
          </div>
        </>
      )}
    </Box>
  );
};

MySavedResources.propTypes = {
  setIsSearching: PropTypes.func.isRequired,
};
