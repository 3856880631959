import { enteredTermActions } from '../../store/enteredTerm-slice';
import { enteredLocationActions } from '../../store/enteredLocation-slice';
import { coordsActions } from '../../store/coords-slice';
import { currentPageActions } from '../../store/currentPage-slice';

export const handleBrowseAll = (
  dispatch,
  navigate,
  setIsSearching,
  termInput = '',
  locationInput = '',
  locationCoords = undefined
) => {
  dispatch(enteredTermActions.handleSearchInput(termInput));
  dispatch(enteredLocationActions.handleLocationInput(locationInput));
  dispatch(coordsActions.logCoords(locationCoords));

  if (
    window.location.pathname === '/' ||
    window.location.pathname.startsWith('/resource')
  ) {
    navigate(
      `/search?page=1&distance=any${`&search=${termInput}`}${locationCoords ? `&address=${JSON.stringify(locationCoords)}` : ''}`,
      { state: 1 }
    );
  } else if (window.location.pathname === '/search') {
    dispatch(currentPageActions.pageHandler(1));
    if (setIsSearching) setIsSearching(true);
  }
};
