import React, { useState } from 'react';
import { Container } from '@mui/material';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Alert from '../../components/Alert/Alert';
import { axiosPrivate } from '../../apis/backend';
import { Box, Button, Grid, Typography, TextField } from '@mui/material';

function EmailSignIn() {
  const user = useSelector((state) => state.auth.user);
  // console.log(user);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [errObject, setErrObject] = useState(undefined);

  const handleInputChange = (fieldName, e) => {
    // console.log('Before update:', formData);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: e.target.value,
    }));
    //console.log('After update:', formData);
  };

  const [formErrors] = useState({
    email: '',
  });

  const formFields = [
    {
      name: 'Email',
      disabled: user?.email,
      id: 'email',
      placeholder: user?.email,
      autoComplete: 'email',
    },
    {
      name: 'Password',
      disabled: user?.password,
      id: 'password',
      placeholder: user?.password,
      autoComplete: 'password',
    },
  ];

  const sendData = async (data) => {
    try {
      // console.log('a1');
      const response = await axiosPrivate.post('/user/emailVerification', data);
      sessionStorage.setItem('isVerified', true);
      sessionStorage.setItem('email', data.email);
      return response;
    } catch (error) {
      setErrObject({
        title: "Oops! We've encountered a problem",
        message:
          error?.response?.data?.errMsg ||
          'An unexpected error occurred. Please try again later.',
      });
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form data

    // Send the form data to the server using postData function
    const dataToSend = {
      email: formData.email,
      password: formData.password,
    };

    // Call the sendData function to make the POST request
    if (formData.email.trim() === '') {
      setErrObject({
        title: "Oops! We've encountered a problem",
        message: 'Email Address is required',
      });
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      if (!/\S+@/.test(formData.email)) {
        setErrObject({
          title: "Oops! We've encountered a problem",
          message: 'Email Address requires an @',
        });
      } else if (!/\S+@\S+\./.test(formData.email)) {
        setErrObject({
          title: "Oops! We've encountered a problem",
          message: 'Email Address requires a .',
        });
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        setErrObject({
          title: "Oops! We've encountered a problem",
          message: 'Email Address requires a domain name',
        });
      }
    } else {
      try {
        const response = await sendData(dataToSend);
        // console.log(response);
        if (response !== false) {
          window.location.href = '/dashboard';
        }
      } catch (error) {
        console.error(
          'An error occurred during form submission:',
          error.message
        );
        // Handle the error as needed
      }
    }
  };

  const moveToSignUp = async () => {
    window.location.href = '/auth/signin/EmailSignUp';
  };

  const moveToPasswordReset = async () => {
    window.location.href = '/auth/signin/ForgotPassword';
  };

  return (
    <>
      <Header />
      <Box sx={{ pb: '30px' }}>
        <Container
          maxWidth="lg"
          style={{ border: '1px solid #CACCD3', borderRadius: '10px' }}
        >
          <Box
            sx={{
              mb: '2rem',
              marginTop: '2rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box component="form" onSubmit={handleSubmit} noValidate>
              {errObject && (
                <Grid item xs={12} paddingBottom={3}>
                  <Alert data={errObject} />
                </Grid>
              )}

              {formFields.map((formField) => {
                const textFieldStyles = {
                  borderRadius: '1.2rem',
                  fontSize: '1.8rem',
                  fontFamily: 'Nunito',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: '24px',
                };
                const redAsteriskStyles = {
                  ...textFieldStyles,
                  color: 'red',
                };

                return (
                  <Fragment key={formField.id}>
                    <Typography variant="h5" sx={{ fontWeight: '500' }}>
                      <span style={textFieldStyles}> {formField.name}</span>
                      {formField.required && (
                        <span style={redAsteriskStyles}> *</span>
                      )}
                    </Typography>
                    <TextField
                      disabled={formField.disabled}
                      required={formField.required}
                      multiline={formField.multiline}
                      fullWidth
                      id={formField.id}
                      placeholder={formField.placeholder}
                      autoComplete={formField.autoComplete}
                      error={formErrors[formField.id]}
                      helperText={formErrors[formField.id]}
                      value={formData[formField.id]} // Use the value from formData
                      onChange={(e) => handleInputChange(formField.id, e)} // Pass the property name to handleInputChange
                      InputProps={{
                        style: {
                          borderRadius: '1.2rem',
                          fontSize: '1.8rem',
                        },
                      }}
                      sx={{
                        margin: '0.7rem 0',
                        'fieldset.MuiOutlinedInput-notchedOutline': {
                          border: '2px solid #00233D',
                          borderRadius: '10px',
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#00233D',
                            borderWidth: '2px',
                          },
                        },
                        '& .MuiInputBase-input': {
                          fontSize: '1.6rem',
                          padding: '1rem 1rem',
                        },
                        padding: '0 0 0.8rem 0',
                      }}
                    />
                  </Fragment>
                );
              })}
              <Box
                sx={{
                  mb: '2rem',
                  marginTop: '2rem',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    borderColor: '#B3BAC5',
                    width: '320px',
                    display: 'flex',
                    borderRadius: '12px',
                    padding: '17px 20px',
                    boxSizing: 'border-box',
                  }}
                  type="submit"
                  fullWidth="true"
                  onClick={handleSubmit}
                >
                  <div
                    style={{
                      color: '#FFFFFF',
                      textAlign: 'center', // Note the correct spelling of textAlign
                      fontFamily: 'Nunito', // Note the correct spelling of fontFamily
                      fontSize: '18px', // Note the correct spelling of fontSize
                      fontStyle: 'normal', // Note the correct spelling of fontStyle
                      fontWeight: '700', // Use fontWeight to make the text bold
                      lineHeight: '20px',
                      textTransform: 'none',
                    }}
                  >
                    Submit
                  </div>
                </Button>
                <Button
                  variant="contained"
                  style={{
                    borderColor: '#B3BAC5',
                    width: '320px',
                    display: 'flex',
                    borderRadius: '12px',
                    padding: '17px 20px',
                    boxSizing: 'border-box',
                  }}
                  fullWidth="true"
                  onClick={moveToSignUp}
                >
                  <div
                    style={{
                      color: '#FFFFFF',
                      textAlign: 'center', // Note the correct spelling of textAlign
                      fontFamily: 'Nunito', // Note the correct spelling of fontFamily
                      fontSize: '18px', // Note the correct spelling of fontSize
                      fontStyle: 'normal', // Note the correct spelling of fontStyle
                      fontWeight: '700', // Use fontWeight to make the text bold
                      lineHeight: '20px',
                      textTransform: 'none',
                    }}
                  >
                    Click here to sign up
                  </div>
                </Button>
                <Button
                  variant="contained"
                  style={{
                    borderColor: '#B3BAC5',
                    width: '320px',
                    display: 'flex',
                    borderRadius: '12px',
                    padding: '17px 20px',
                    boxSizing: 'border-box',
                  }}
                  fullWidth="true"
                  onClick={moveToPasswordReset}
                >
                  <div
                    style={{
                      color: '#FFFFFF',
                      textAlign: 'center', // Note the correct spelling of textAlign
                      fontFamily: 'Nunito', // Note the correct spelling of fontFamily
                      fontSize: '18px', // Note the correct spelling of fontSize
                      fontStyle: 'normal', // Note the correct spelling of fontStyle
                      fontWeight: '700', // Use fontWeight to make the text bold
                      lineHeight: '20px',
                      textTransform: 'none',
                    }}
                  >
                    Click here to reset password
                  </div>
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
}

export default EmailSignIn;
