import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Alert from '../../components/Alert/Alert';
import { axiosPrivate } from '../../apis/backend';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Account = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);
  // console.log(user);

  const [formData, setFormData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    orgName: user.orgName,
    email: user.email,
    orgAddress: '',
    orgPhone: user.orgPhone,
    website: '',
    jobTitle: user.jobTitle,
    additionalInformation: '',
  });

  const [errObject, setErrObject] = useState(undefined);

  const handleInputChange = (fieldName, e) => {
    // console.log('Before update:', formData);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: e.target.value,
    }));
    // console.log('After update:', formData);
  };

  // eslint-disable-next-line no-unused-vars
  const handleClear = () => {
    // In case the commented content comes back, I'm keeping this function
    setFormData((prevFormData) => {
      const clearedFormData = {};
      // Iterate over keys in prevFormData and set each to an empty string
      for (const key in prevFormData) {
        clearedFormData[key] = '';
      }
      return clearedFormData;
    });

    setFormErrors({
      firstName: '',
      lastName: '',
      orgName: '',
      email: '',
      orgAddress: '',
      orgPhone: '',
      website: '',
      jobTitle: '',
      additionalInformation: '',
    });
  };

  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    orgName: '',
    email: '',
    orgAddress: '',
    orgPhone: '',
    website: '',
    jobTitle: '',
    additionalInformation: '',
  });

  const sx = {
    margin: '0.7rem 0',
    'fieldset.MuiOutlinedInput-notchedOutline': {
      border: '2px solid #00233D',
      borderRadius: '10px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#00233D',
        borderWidth: '2px',
      },
    },
    '& .MuiInputBase-input': {
      fontSize: '1.6rem',
      padding: '1rem 1rem',
    },
    padding: '0 0 0.8rem 0',
  };

  const formFields = [
    {
      name: 'First Name',
      disabled: true,
      id: 'firstName',
      placeholder: user?.firstName,
      autoComplete: 'first-name',
    },
    {
      name: 'Last Name',
      // disabled: user?.lastName,
      disabled: true,
      id: 'lastName',
      placeholder: user?.lastName,
      autoComplete: 'last-name',
    },
    {
      name: 'Email',
      // disabled: user?.email,
      disabled: true,
      id: 'email',
      placeholder: user?.email,
      autoComplete: 'email',
    },
    {
      name: 'Your Job Title',
      disabled: true,
      // required: true,
      id: 'jobTitle',
      placeholder: user?.registrationInfo
        ? JSON.parse(user?.registrationInfo)?.jobTitle
        : '',
      autoComplete: 'job-title',
    },
    {
      name: 'Organization Name',
      disabled: true,
      // required: true,
      id: 'orgName',
      placeholder: user?.registrationInfo
        ? JSON.parse(user?.registrationInfo)?.jobTitle
        : '',
      autoComplete: 'org-name',
    },
    {
      name: 'Organization Address',
      disabled: true,
      id: 'orgAddress',
      placeholder: user?.registrationInfo
        ? JSON.parse(user?.registrationInfo)?.orgName
        : '',
      autoComplete: 'org-address',
    },
    {
      name: 'Website',
      disabled: true,
      id: 'website',
      placeholder: user?.registrationInfo
        ? JSON.parse(user?.registrationInfo)?.website
        : '',
      autoComplete: 'org-website',
    },
    {
      name: 'Phone Number',
      // required: true,
      disabled: true,
      id: 'orgPhone',
      placeholder: user?.registrationInfo
        ? JSON.parse(user?.registrationInfo)?.orgPhone
        : '',
      autoComplete: 'org-phone',
    },
    // {
    //   name: 'Additional Information',
    //   multiline: true,
    //   id: 'additionalInformation',
    //   placeholder:
    //     'ex: Florida Community Innovation (FCI) envisions a Florida where everyone has equal access to trusted information, critical services, and rewarding careers.',
    //   autoComplete: 'additional-information',
    // },
  ];

  const sendData = async (data) => {
    if (Object.keys(validateForm()).length !== 0) return;
    try {
      const response = await axiosPrivate.post('/user/registration', data);
      if (response?.data?.errCode === '0') {
        window.location.href = '/dashboard';
      } else {
        throw Error;
      }
    } catch (error) {
      setErrObject({
        title: "Oops! We've encountered a problem",
        message:
          error?.response?.data?.errMsg ||
          'An unexpected error occurred. Please try again later.',
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form data
    validateForm();

    // Send the form data to the server using postData function
    const dataToSend = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      // orgName: formData.orgName,
      email: formData.email,
      // orgAddress: formData.orgAddress,
      orgPhone: formData.orgPhone,
      // website: formData.website,
      jobTitle: formData.jobTitle,
      // additionalInformation: formData.additionalInformation,
    };

    // Call the sendData function to make the POST request
    try {
      await sendData(dataToSend);
    } catch (error) {
      console.error('An error occurred during form submission:', error.message);
      // Handle the error as needed
    }
  };

  const validateForm = () => {
    // const errors = {};
    // if (formData.orgName.trim() === '') {
    //   errors.orgName = 'Organization name is required';
    // } else if (formData.orgName.length < 5 || formData.orgName.length > 60) {
    //   errors.orgName =
    //     'Organization name should be between 5 and 60 characters';
    // }
    // if (formData.orgAddress.trim() === '') {
    //   errors.orgAddress = 'Address is required';
    // } else if (formData.orgAddress.length < 5 || formData.orgAddress.length > 60) {
    //   errors.orgAddress = 'Address should be between 5 and 60 characters';
    // }
    // if (formData.website.trim() === '') {
    //   errors.website = 'Website is required';
    // } else if (!/\S+.\S+/.test(formData.website)) {
    //   errors.website = 'Invalid Website';
    // }
    // if (formData.orgPhone.trim() === '') {
    //   errors.orgPhone = 'Phone number is required';
    // } else if (!/^[0-9]+$/.test(formData.orgPhone)) {
    //   errors.orgPhone = 'Phone number should only contain numbers';
    // }
    // if (formData.jobTitle.trim() === '') {
    //   errors.jobTitle = 'Job title is required';
    // } else if (formData.jobTitle.length < 5 || formData.jobTitle.length > 60) {
    //   errors.jobTitle = 'Job title should be between 5 and 60 characters';
    // }
    // // if (formData.additionalInformation.trim() === '') {
    // //   errors.additionalInformation = 'Additional Information is required';
    // // }
    // setFormErrors(errors);
    // return errors;
  };

  return (
    <>
      {/* <ThemeProvider theme={defaultTheme}> */}
      <Container maxWidth="lg" sx={{ marginY: '32px' }}>
        <Alert
          data={{
            severity: 'info',
            title: t('Information'),
            message: t(
              'You may review your registration information bellow. To update your account profile, please email us at frm-support@floridainnovation.org to have changes reviewed and approved by our team. Thanks for your cooperation!'
            ),
          }}
        />
      </Container>

      <Box sx={{ pb: '30px', marginTop: '20px' }}>
        <Container
          component="main"
          maxWidth="lg"
          style={{ border: '1px solid #CACCD3', borderRadius: '10px' }}
        >
          {/* <CssBaseline /> */}

          <Box
            sx={{
              mb: '2rem',
              marginTop: '2rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <div
                className="signup__heading"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '11px',
                  alignSelf: 'stretch',
                  paddingTop: '15px',
                }}
              >
                <p
                  className="p2"
                  style={{
                    color: '#00233D',
                    'font-size': '30px',
                    fontWeight: 'bold',
                    marginBottom: '15px',
                  }}
                >
                  {t('Profile Information')}
                </p>
                <br />
              </div>

              {errObject && (
                <Grid item xs={12} paddingBottom={3}>
                  <Alert data={errObject} />
                </Grid>
              )}

              {formFields.map((formField) => {
                const textFieldStyles = {
                  borderRadius: '1.2rem',
                  fontSize: '1.8rem',
                  fontFamily: 'Nunito',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: '24px',
                };
                const redAsteriskStyles = {
                  ...textFieldStyles,
                  color: 'red',
                };

                return (
                  <Fragment key={formField.id}>
                    <Typography variant="h5" sx={{ fontWeight: '500' }}>
                      <span style={textFieldStyles}> {t(formField.name)}</span>
                      {formField.required && (
                        <span style={redAsteriskStyles}> *</span>
                      )}
                    </Typography>
                    <TextField
                      disabled={formField.disabled}
                      required={formField.required}
                      multiline={formField.multiline}
                      fullWidth
                      id={t(formField.id)}
                      placeholder={t(formField.placeholder)}
                      autoComplete={formField.autoComplete}
                      error={formErrors[formField.id]}
                      helperText={formErrors[formField.id]}
                      value={formData[formField.id]} // Use the value from formData
                      onChange={(e) => handleInputChange(formField.id, e)} // Pass the property name to handleInputChange
                      InputProps={{
                        style: {
                          borderRadius: '1.2rem',
                          fontSize: '1.8rem',
                        },
                      }}
                      sx={{ ...sx }}
                    />
                  </Fragment>
                );
              })}
              <div style={{ width: '500px' }} />

              <Box
                sx={{
                  mb: '2rem',
                  marginTop: '2rem',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {/* <Button
                  style={{
                    width: '320px',
                    border: '2px solid #0052CC',
                    borderRadius: '12px',
                    padding: '16px',
                    boxSizing: 'border-box',
                    // Add this to include padding and border in the width
                  }}
                  fullWidth
                  type="submit"
                  onClick={handleClear}
                >
                  <div
                    style={{
                      color: '#0052CC',
                      textAlign: 'center', // Note the correct spelling of textAlign
                      fontFamily: 'Nunito', // Note the correct spelling of fontFamily
                      fontSize: '18px', // Note the correct spelling of fontSize
                      fontStyle: 'normal', // Note the correct spelling of fontStyle
                      fontWeight: '700', // Use fontWeight to make the text bold
                      lineHeight: '15px',
                      textTransform: 'none',
                    }}
                  >
                    Clear
                  </div>
                </Button>

                <div style={{width: "20px"}} />

                <Button
                  variant="contained"
                  style={{
                    borderColor: "#B3BAC5",
                    width: '320px',
                    display: "flex",
                    borderRadius: '12px',
                    padding: '17px 20px',
                    boxSizing: 'border-box',
                    backgroundColor: "#0052CC"

                  }}
                  type="submit"
                  fullWidth="true"
                  onClick={handleSubmit}
                >
                  <div
                    style={{
                      color: '#FFFFFF',
                      textAlign: 'center', // Note the correct spelling of textAlign
                      fontFamily: 'Nunito', // Note the correct spelling of fontFamily
                      fontSize: '18px', // Note the correct spelling of fontSize
                      fontStyle: 'normal', // Note the correct spelling of fontStyle
                      fontWeight: '700', // Use fontWeight to make the text bold
                      lineHeight: '15px',
                      textTransform: 'none',
                    }}
                  >
                    Update Profile
                  </div>
                </Button> */}
              </Box>

              {/* <p><b>Organization Name</b></p>
                <TextField     // How to make that form part title look better?
                  name="orgName"
                  required
                  fullWidth
                  id="orgName"
                  placeholder="ex: Florida Community Innovation"  // Probably not better but we'll try it for now
                  autoComplete="org-name"
                  error={Boolean(formErrors.orgName)}
                  helperText={formErrors.orgName}
                  onChange={(e) => { setFormData({ ...formData, orgName: e.target.value }); }}
                  InputProps={{
                    style: {
                      borderRadius: '1.2rem',
                      fontSize: '1.8rem',
                    },
                  }}
                  sx={{ ...sx }}
                />

                <p><b>Organization Address</b></p>
                <TextField
                  name="s" 
                  required
                  fullWidth
                  id="s"
                  placeholder="ex: Gainesville, Florida"
                  autoComplete="Gates"   // Why isn't this showing up?
                  error={Boolean(formErrors.address)}
                  helperText={formErrors.address}
                  sx={{ ...sx, mb: '2rem' }}
                  onChange={(e) => {
                    setFormData({ ...formData, address: e.target.value });
                  }
                  }
                />

                <Box sx={{
                  mb: '2rem',
                  justifyContent: 'space-between',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>

                  <Box sx={{

                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                  }}>

                    <p><b>Website</b></p>
                    <TextField
                      name="website"
                      required
                      fullWidth
                      id="website"
                      label="ex: https://florida-resource-map.com"
                      autoComplete="Gates"   // Why isn't this showing up?   Extra comment
                      sx={sx}
                      error={Boolean(formErrors.website)}
                      helperText={formErrors.website}
                      onChange={(e) => {
                        setFormData({ ...formData, website: e.target.value });
                      }
                      }
                    /> </Box>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                  }}>
                    <p><b>Phone</b></p>
                    <TextField
                      name="phone"
                      required
                      fullWidth
                      id="phone"
                      label="ex: (352) XXX-XXXX"
                      autoComplete="num"   // Why isn't this showing up?
                      error={Boolean(formErrors.phone)}
                      helperText={formErrors.phone}
                      sx={sx}
                      onChange={(e) => {
                        setFormData({ ...formData, phone: e.target.value });
                      }
                      }
                    />
                  </Box>
                </Box>
                <p><big><b>Your Job Title</b></big></p>
                <TextField
                  name="job"
                  required
                  fullWidth
                  id="job"
                  label="ex: Underwater Basket Weaver"
                  autoComplete="worker"   // Why isn't this showing up?
                  error={Boolean(formErrors.jobTitle)}
                  helperText={formErrors.jobTitle}
                  sx={{ ...sx, mb: '2rem' }}
                  onChange={(e) => {
                    setFormData({ ...formData, jobTitle: e.target.value });
                  }
                  }
                />
                <p><big><b>Additional Description</b></big></p>
                <TextField
                  name="description"
                  fullWidth
                  id="description"
                  label="ex: Non-profit focused on helping the Florida community."
                  autoComplete="description"   // Why isn't this showing up?
                  sx={sz}
                  onChange={(e) => {
                    setFormData({ ...formData, description: e.target.value });
                  }
                  }
                />
                <br />

                */}
            </Box>
          </Box>
        </Container>
      </Box>
      {/* </ThemeProvider> */}
    </>
  );
};

export { Account };
