import React, { useState } from 'react';
import { Container } from '@mui/material';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import SignInOptionsPage from './SignInOptionsPage'; // Updated import
import VerificationPage from './VerificationPage'; // Updated import
import MapBackground from '../../resources/Map-Background.png';
import PropTypes from 'prop-types';

const backgroundStyle = {
  backgroundImage: `url(${MapBackground})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

function SignIn({ callbackIdP }) {
  /*function SignIn() { */
  const [emailVerify, setEmailVerify] = useState(false);

  const handleNext = () => {
    setEmailVerify(true);
  };

  const handleReturn = () => {
    setEmailVerify(false);
  };

  return (
    <div
      style={{
        ...backgroundStyle,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Header />
      <Container maxWidth="lg">
        {emailVerify ? (
          <VerificationPage handleReturn={handleReturn} />
        ) : (
          <SignInOptionsPage
            handleNext={handleNext}
            callbackIdP={callbackIdP}
          />
        )}
      </Container>
      <Footer />
    </div>
  );
}
SignIn.propTypes = {
  callbackIdP: PropTypes.string,
};

export default SignIn;
