import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, Box } from '@mui/material';
import { ZoomSlider } from '../ZoomSlider/ZoomSlider';
import { LanguageSelector } from '../LanguageSelector/LanguageSelector';

const AccessibilityMenu = ({ zoomLevel, setZoomLevel }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {/* Button with SVG Icon */}
      <button
        className="accessibility-menu__button"
        onClick={handleClick}
        style={{
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          padding: '0',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <circle cx="16" cy="16" r="11.8" stroke="#174995" strokeWidth="2" />
          <path
            d="M4.80005 12H27.2001M4.80005 20H27.2001M16.6826 27.269C22.1735 20.9727 22.1725 10.2511 16.6798 4.64281C16.3114 4.26669 15.7078 4.28733 15.3586 4.68131C9.82815 10.9208 9.81391 21.011 15.3158 27.2703C15.6805 27.6851 16.3196 27.6853 16.6826 27.269Z"
            stroke="#174995"
            strokeWidth="2"
          />
        </svg>
      </button>

      {/* Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 3,
          sx: {
            borderRadius: '8px',
            background: '#FFF',
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
            width: '220px',
            padding: '8px 0',
          },
        }}
      >
        <Box sx={{ padding: '8px 16px' }}>
          {/* Language Selector */}
          <MenuItem disableRipple>
            <LanguageSelector />
          </MenuItem>

          {/* Zoom Slider */}
          <MenuItem disableRipple>
            <ZoomSlider zoomLevel={zoomLevel} setZoomLevel={setZoomLevel} />
          </MenuItem>
        </Box>
      </Menu>
    </div>
  );
};

// ✅ Add PropTypes validation
AccessibilityMenu.propTypes = {
  zoomLevel: PropTypes.number.isRequired,
  setZoomLevel: PropTypes.func.isRequired,
};

export default AccessibilityMenu;
