import React from 'react';
import PropTypes from 'prop-types';
import './_Reviews.scss';
import { Rating, Typography } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';

Reviews.propTypes = {
  reviewer: PropTypes.string.isRequired, // Reviewer ID (opid)
  reviewText: PropTypes.string.isRequired,
  reviewTime: PropTypes.string.isRequired, // Assuming reviewTime is passed as a string
  rating: PropTypes.number.isRequired,
};

function Reviews({ reviewer, reviewText, reviewTime, rating }) {
  const formattedReviewTime = formatDistanceToNow(new Date(reviewTime), {
    addSuffix: true,
  });

  return (
    <div className="container">
      <div className="content-wrapper" style={{ cursor: 'default' }}>
        <div className="content" style={{ cursor: 'default' }}>
          <div className="box">
            <Typography
              style={{
                fontWeight: 'bold',
                fontSize: 18,
                color: '#2C2D30',
                cursor: 'default',
              }}
            >
              {reviewer} {/* Display the name or a fallback */}
            </Typography>
          </div>
        </div>
      </div>
      <div className="rating-time-wrapper">
        <Rating name="read-only" value={rating} readOnly />
        <div className="review-time">{formattedReviewTime}</div>
      </div>
      <div className="review-text">{reviewText}</div>
      <div className="button-group">
        {/* Uncomment and implement button actions if needed */}
        {/* 
        <Button
          variant="primary"
          label="Helpful"
          // onClick={handleClick}
        />
        <Button
          variant="secondary"
          label="Report"
          // onClick={handleClick}
        />
        */}
      </div>
    </div>
  );
}

export default Reviews;
