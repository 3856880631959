import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Pagination } from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';

export const Pagination1 = ({ totalPages = 1 }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const initialPage = parseInt(searchParams.get('page')) || 1; // reads initial page #
  const [currentPage, setCurrentPage] = useState(initialPage);

  const handleChange = (event, page) => {
    setCurrentPage(page);
    // Create a copy of the existing search parameters
    const newParams = new URLSearchParams(searchParams.toString());

    // Update the 'page' parameter in the copied parameters
    newParams.set('page', page);

    // Update the URL with the merged parameters
    navigate(`?${newParams.toString()}`, { replace: true }); //This preserves URL Parameters but also updates the page.
  };

  // sync current page with url changes (page navigation back/forward)
  useEffect(() => {
    const pageInUrl = parseInt(searchParams.get('page')) || 1;
    if (pageInUrl !== currentPage) {
      setCurrentPage(pageInUrl); // set current page # after url page # change
    }
  }, [searchParams, currentPage]);

  return (
    <div className="search-page__pagination">
      <Pagination
        count={totalPages > 0 ? totalPages : 1}
        page={currentPage}
        color="primary"
        variant="outlined"
        shape="rounded"
        size="large"
        onChange={handleChange}
        sx={{
          'Button.MuiPaginationItem-rounded.Mui-selected': {
            bgcolor: '#00233D',
            color: 'white',
          },
          'Button.MuiPaginationItem-rounded': { borderRadius: '12px' },
          'Button.MuiPaginationItem-previousNext': { border: 'none' },
        }}
      />
    </div>
  );
};

Pagination1.propTypes = {
  totalPages: PropTypes.number,
};
