import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from 'react-leaflet';
import PropTypes from 'prop-types';
import { Button, LocationPin, CardResponsive } from '../';
import * as ReactDOM from 'react-dom/server';
import L from 'leaflet';
import Cookies from 'universal-cookie';
import getDistance from 'geolib/es/getDistance';
import { distanceActions } from '../../store/distance-slice';
import { useTranslation } from 'react-i18next';

export const ResourceMap = ({ pins, resourceCounterOffset }) => {
  const { t } = useTranslation();

  const popupRef = useRef();
  const mapRef = useRef();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const [currentCoords, setCurrentCoords] = useState(null);
  const locationCoords = useSelector((state) => state.coords.coordinates);

  const findMe = () => {
    const map = mapRef.current;

    // Callback functions after attempting to retrieve location:
    const successCallback = (position) => {
      const newLocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };

      // Set cookies
      cookies.set('location', JSON.stringify(newLocation), { path: '/' });

      // Set Current Coords
      setCurrentCoords(newLocation);

      // Fly to new location on the map
      if (map) {
        map.flyTo([newLocation.lat, newLocation.lng], 13);
      }

      dispatch(distanceActions.toggle(true));
    };

    const errorCallback = (error) => {
      if (error.code === 1) {
        window.alert('Please allow your location access');
      }
    };

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
      enableHighAccuracy: true,
      timeout: 10000,
    });
  };

  return (
    <>
      {pins ? (
        <MapContainer
          center={
            locationCoords
              ? [locationCoords.lat, locationCoords.lng]
              : [27.750354, -81.299718]
          }
          ref={mapRef}
          zoom={locationCoords ? 7 : 6}
          style={{ width: '100%', height: '100%' }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <div className="search-page__buttons-wrapper--secondary">
            <Button
              onClick={findMe}
              variant="secondaryMap"
              size="large"
              label={t('Find Me')}
              iconName="locate"
            />
          </div>

          {pins
            .filter((pin) => pin.latitude && pin.longitude)
            .map((r, index) => {
              return (
                <Marker
                  key={index}
                  position={[r.latitude, r.longitude]}
                  ref={popupRef}
                  eventHandlers={{
                    mouseover: (event) => event.target.openPopup(),
                    mouseout: (e) => {
                      window.setTimeout(() => {
                        e.target.closePopup();
                      }, 2000);
                    },
                  }}
                  icon={L.divIcon({
                    className: '',
                    iconSize: [48, 48],
                    iconAnchor: [24, 48],
                    html: ReactDOM.renderToString(
                      <LocationPin
                        // number={10 * (currentPage - 1) + (index + 1)}
                        number={index + 1 + (resourceCounterOffset || 0)}
                      />
                    ),
                  })}
                >
                  {/* Popup on hover for desktop, tap for mobile */}
                  <Popup className="desktop-popup">
                    <CardResponsive
                      key={index}
                      id={r._id}
                      header={r.name}
                      address={r.address}
                      website={r.website}
                      tags={r.tags}
                      index={index}
                      active
                      distance={
                        locationCoords && r.latitude
                          ? `${(
                              getDistance(
                                {
                                  latitude: locationCoords.lat,
                                  longitude: locationCoords.lng,
                                },
                                {
                                  latitude: r.latitude,
                                  longitude: r.longitude,
                                }
                              ) * 0.000621371
                            ).toFixed(1)}  miles away`
                          : ''
                      }
                    />
                  </Popup>
                </Marker>
              );
            })}

          {/* User's location pin */}
          {currentCoords && (
            <Marker
              position={[currentCoords.lat, currentCoords.lng]}
              icon={L.divIcon({
                className: '',
                iconSize: [48, 48],
                iconAnchor: [24, 48],
                html: ReactDOM.renderToString(
                  <LocationPin number="" isYou={true} />
                ),
              })}
            >
              <Tooltip
                className="youMessage"
                direction="top"
                offset={[0, -40]}
                opacity={1}
              >
                You are here
              </Tooltip>
            </Marker>
          )}
        </MapContainer>
      ) : (
        '...Loading...'
      )}
    </>
  );
};

ResourceMap.propTypes = {
  pins: PropTypes.array,
  resourceCounterOffset: PropTypes.number,
};

ResourceMap.defaultProps = {
  pins: [],
};
