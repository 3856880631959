import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SearchBar } from '../SearchBar/SearchBar';
import { Logo } from '../Logo/Logo';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { enteredTermActions } from '../../store/enteredTerm-slice';
import { enteredLocationActions } from '../../store/enteredLocation-slice';
import { userActions } from '../../store/auth-slice';
import { coordsActions } from '../../store/coords-slice';
import { axiosPrivate } from '../../apis/backend';
import { useTranslation } from 'react-i18next';
import { LanguageSelector } from '../LanguageSelector/LanguageSelector';
import { ZoomSlider } from '../ZoomSlider/ZoomSlider';
import { Button } from '../Button/Button';
import UserMenu from '../UserMenu/UserMenu';
import AccessibilityMenu from '../AccessibilityMenu/AccessibilityMenu';
import { handleBrowseAll } from '../BrowseAll/BrowseAll';

Header.propTypes = {
  landing: PropTypes.bool,
  setIsSearching: PropTypes.func,
  dashboard: PropTypes.bool,
  searchBar: PropTypes.bool,
};

export function Header({ landing, setIsSearching, dashboard, searchBar }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const authed = user?.roles?.includes('100001');
  const [zoomLevel, setZoomLevel] = useState(100);
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      axiosPrivate.post('/auth/signOut');
    } finally {
      dispatch(userActions.setUser(undefined));
    }
  };

  const handleClear = () => {
    dispatch(enteredTermActions.handleSearchInput(''));
    dispatch(enteredLocationActions.handleLocationInput(''));
    dispatch(coordsActions.logCoords(undefined));
  };

  const handleBrowseAllClick = () => {
    handleBrowseAll(dispatch, navigate, setIsSearching);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (landing) {
    return (
      <div className="header-landing">
        {isMobile ? (
          // **MOBILE HEADER**
          <div className="header-landing__mobile">
            <div className="header-landing__head">
              <Logo />
              <div className="header-landing__btnwrapper">
                {/* <span className="header-landing__language">Español</span> */}
                <div className="user-menu">
                  <UserMenu authed={authed} handleSignOut={handleSignOut} />
                </div>
                <div className="accessibility-menu">
                  <AccessibilityMenu
                    zoomLevel={zoomLevel}
                    setZoomLevel={setZoomLevel}
                  />
                </div>
              </div>
            </div>
            <div className="header-landing__search">
              <SearchBar
                className="header-search__search-mobile"
                setIsSearching={setIsSearching}
                currentPage="landing"
              />
            </div>
            <div className="header-landing__container">
              <div className="header-landing__chapter">
                <div className="header-landing__welcome-mobile">
                  {t('Find local programs that provide the help you need.')}
                </div>
                <div className="header-landing__description-mobile">
                  {t(
                    'Whether it’s food, housing, health care, financial assistance or more.'
                  )}
                </div>
                <div className="header-landing__search-wrapper">
                  <SearchBar
                    className="header-landing__search"
                    setIsSearching={setIsSearching}
                    currentPage="landing"
                  />
                </div>
              </div>
              <div className="header-landing__illustration">
                <img
                  decoding="async"
                  loading="eager"
                  src="/media/landing-illustration.svg"
                  title="Decorative illustration about shelter, food, and medical resources"
                  alt="Decorative illustration about shelter, food, and medical resources"
                />
              </div>
            </div>
            <div className="header-landing__browse-wrapper">
              <p className="header-landing__browse-text">Or, simply</p>
              <div className="header-landing__browse">
                <Button
                  label={t('Browse all')}
                  type="button"
                  icon="arrow-forward"
                  variant="browse-all-button"
                  onClick={handleBrowseAllClick}
                />
              </div>
            </div>
            <div className="header-partner-logos">
              <img
                decoding="async"
                loading="eager"
                src="/media/Frame 447.svg"
                title="Decorative illustration about shelter, food, and medical resources"
                alt="Decorative illustration about shelter, food, and medical resources"
              />
            </div>
          </div>
        ) : (
          // **DESKTOP HEADER**
          <div className="header-landing__desktop">
            <div className="header-landing__head">
              <Logo />
              <div className="header-landing__btnwrapper">
                {/* <span className="header-landing__language">Español</span> */}
                <ZoomSlider zoomLevel={zoomLevel} setZoomLevel={setZoomLevel} />
                <LanguageSelector />
                {authed && (
                  <span
                    className="header-landing__signout"
                    onClick={() => navigate(`/dashboard`)}
                  >
                    {t('Dashboard')}
                  </span>
                )}
                {authed ? (
                  <span
                    className="header-search__signout"
                    onClick={handleSignOut}
                  >
                    {t('Sign Out')}
                  </span>
                ) : (
                  <span
                    className="header-search__signout"
                    onClick={() => navigate(`/auth/signin`)}
                  >
                    {t('Sign In')}
                  </span>
                )}
              </div>
            </div>
            <div className="header-search__mobile">
              <SearchBar
                className="header-search__search-mobile"
                setIsSearching={setIsSearching}
                currentPage="landing"
              />
            </div>
            <div className="header-landing__container">
              <div className="header-landing__chapter">
                <div className="header-landing__welcome heading-primary">
                  {t('Find local programs that provide the help you need.')}
                </div>
                <div className="header-landing__description">
                  {t(
                    'Whether it’s food, housing, health care, financial assistance or more.'
                  )}
                </div>
                <div className="header-landing__search-wrapper">
                  <SearchBar
                    className="header-landing__search"
                    setIsSearching={setIsSearching}
                    currentPage="landing"
                  />
                </div>
              </div>
              <div className="header-landing__illustration">
                <img
                  decoding="async"
                  loading="eager"
                  src="/media/landing-illustration.svg"
                  title="Decorative illustration about shelter, food, and medical resources"
                  alt="Decorative illustration about shelter, food, and medical resources"
                />
              </div>
            </div>
            <div className="header-landing__browse">
              <div className="header-landing__browse">
                <div className="header-landing__browse">
                  <Button
                    label={t('Browse all')}
                    type="button"
                    icon="arrow-forward"
                    variant="browse-all-button"
                    onClick={handleBrowseAllClick}
                  />
                </div>
              </div>
            </div>
            <div className="header-partner-sponsors">
              <p>
                {t('With reliable resources from our partners across Florida')}
              </p>
            </div>
            <div className="header-landing__mobile">
              <SearchBar
                className="header-landing__search-mobile"
                setIsSearching={setIsSearching}
                currentPage="search"
              />
            </div>
          </div>
        )}
      </div>
    );
  } else if (dashboard) {
    return (
      <div className="header-dashboard">
        {isMobile ? (
          <div className="header-dashboard__mobile">
            <div className="header-dashboard__head">
              <Link to="/" onClick={handleClear}>
                <Logo />
              </Link>
              <div className="header-dashboard__btnwrapper">
                {/* <div className="header-search__language">Español</div> */}
                <div className="user-menu">
                  <UserMenu authed={authed} handleSignOut={handleSignOut} />
                </div>
                <div className="accessibility-menu">
                  <AccessibilityMenu
                    zoomLevel={zoomLevel}
                    setZoomLevel={setZoomLevel}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="header-dashboard">
            <div className="header-dashboard__desktop">
              <Link to="/" onClick={handleClear}>
                <Logo />
              </Link>
              <div className="header-dashboard__btnwrapper">
                {/* <div className="header-search__language">Español</div> */}
                <ZoomSlider zoomLevel={zoomLevel} setZoomLevel={setZoomLevel} />
                <LanguageSelector />
                {authed && (
                  <span
                    className="header-dashboard__signout"
                    onClick={() => navigate('/')}
                  >
                    {t('Home')}
                  </span>
                )}
                {authed && (
                  <a
                    className="header-dashboard__signout"
                    href="/"
                    onClick={handleSignOut}
                  >
                    {t('Sign Out')}
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  return (
    //Search page header
    <div className="header-search">
      <div className="header-search__left-content">
        <div className="header-search__desktop">
          <Link to="/" onClick={handleClear}>
            <Logo />
          </Link>
          {searchBar && (
            <div className="header-search__search-wrapper">
              <SearchBar
                className="header-search__search"
                setIsSearching={setIsSearching}
                currentPage="search"
              />
            </div>
          )}
        </div>
        <div className="header-search__btnwrapper">
          {/* <div className="header-search__language">Español</div> */}

          {/* Button to open the slider pop-up */}
          <ZoomSlider zoomLevel={zoomLevel} setZoomLevel={setZoomLevel} />
          <LanguageSelector />
          {authed && (
            <span
              className="header-search__signout"
              onClick={() => navigate(`/dashboard`)}
            >
              {t('Dashboard')}
            </span>
          )}
          {authed ? (
            <span className="header-search__signout" onClick={handleSignOut}>
              {t('Sign Out')}
            </span>
          ) : (
            <span
              className="header-search__signout"
              onClick={() => navigate(`/auth/signin`)}
            >
              {t('Sign In')}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
